import {get, post, put,Delete} from "@/api/axios";

export const list = params => get('/web/build/city/list', params);

export const add = params => post('/web/build/city/add', params);

export const view = params => get('/web/build/city/view/' + params.id, '');

export const update = params => put('/web/build/city/update', params);

export const deleteNew = params => Delete('/web/build/city/delete/' + params.id, '');
