<template>
  <div class="main-content">
    <avue-crud
        :data="tableData"
        :option="option"
        :table-loading="showLoading"
        :page.sync="page"
        @on-load="onLoad"
    >
      <template #header>
        <div class="flex align-items marginBottom20">
          <el-button size="medium" type="primary" icon="el-icon-plus" @click="handleCreate">新增</el-button>
          <el-input size="medium" v-model="searchData.name" placeholder="城市名称" class="marginLeft10 width200"/>
          <el-button size="medium" type="primary" icon="el-icon-search" @click="handleSearch" class="marginLeft10">查询</el-button>
          <el-button size="medium" type="info" icon="el-icon-refresh-right" @click="handleReset" class="marginLeft10">重置</el-button>
        </div>
      </template>
      <template slot="menu" slot-scope="{ row }">
        <el-button type="text" size="mini" icon="el-icon-edit" @click="handleEdit(row)">编辑</el-button>
        <el-button type="text" size="mini" icon="el-icon-edit" @click="handleRegion(row)">设置区县</el-button>
        <el-button type="text" size="mini" icon="el-icon-edit" @click="handleMetro(row)">设置地铁</el-button>
        <el-button type="text" size="mini" icon="el-icon-delete" @click="handleDelete(row)">删除</el-button>
      </template>
    </avue-crud>
`
    <el-dialog
        :title="`${form.id ? '编辑' : '新增'}`"
        v-if="dialogVisible"
        :visible.sync="dialogVisible"
        width="40%"
        top="10vh"
        :before-close="handleClose">
      <avue-form ref="form"
                 v-model="form"
                 :option="formOption"
                 :upload-error="uploadError"
                 :upload-delete="uploadDelete"
                 :upload-after="uploadAfter"
                 :upload-before="uploadBefore"
                 @submit="submit">
        <template slot="h3">
          <h3>城市信息</h3>
        </template>
        <template slot="h5One">
          <h5>二手房交易</h5>
        </template>
        <template slot="totalPayServiceFee">
          <el-input v-model="form.totalPayServiceFee"
                    placeholder="请输入 总支付服务费"
                    @blur="changeBlur($event,'totalPayServiceFee',999999)"
                    onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
            <template slot="append">元</template>
          </el-input>
        </template>
        <template slot="sellerPayServiceFee">
          <el-input v-model="form.sellerPayServiceFee"
                    placeholder="请输入 卖方支付服务费"
                    @blur="changeBlur($event,'sellerPayServiceFee',999999)"
                    onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
            <template slot="append">元</template>
          </el-input>
        </template>
        <template slot="buyerPayServiceFee">
          <el-input v-model="form.buyerPayServiceFee"
                    placeholder="请输入 买方支付服务费"
                    @blur="changeBlur($event,'buyerPayServiceFee',999999)"
                    onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
            <template slot="append">元</template>
          </el-input>
        </template>
        <template slot="totalPayCommission">
          <el-input v-model="form.totalPayCommission"
                    placeholder="请输入 总支付佣金"
                    @blur="changeBlur($event,'totalPayCommission',100)"
                    onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
            <template slot="append">%</template>
          </el-input>
        </template>
        <template slot="sellerPayCommission">
          <el-input v-model="form.sellerPayCommission"
                    placeholder="请输入 卖方支付佣金"
                    @blur="changeBlur($event,'sellerPayCommission',100)"
                    onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
            <template slot="append">%</template>
          </el-input>
        </template>
        <template slot="buyerPayCommission">
          <el-input v-model="form.buyerPayCommission"
                    placeholder="请输入 买方支付佣金"
                    @blur="changeBlur($event,'buyerPayCommission',100)"
                    onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
            <template slot="append">%</template>
          </el-input>
        </template>
        <template slot="enterSubLedgerAmount">
          <el-input v-model="form.enterSubLedgerAmount"
                    placeholder="请输入 服务费-录入人分账金额"
                    @blur="changeBlur($event,'enterSubLedgerAmount',999999)"
                    onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
            <template slot="append">元</template>
          </el-input>
        </template>
        <template slot="handlerSubLedgerAmount">
          <el-input v-model="form.handlerSubLedgerAmount"
                    placeholder="请输入 服务费-办理人分账金额"
                    @blur="changeBlur($event,'handlerSubLedgerAmount',999999)"
                    onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
            <template slot="append">元</template>
          </el-input>
        </template>

        <template slot="commissionPaySubLedgerScale">
          <el-input v-model="form.commissionPaySubLedgerScale"
                    placeholder="请输入 佣金-支付方分账比例"
                    @blur="changeBlur($event,'commissionPaySubLedgerScale',100)"
                    onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
            <template slot="append">%</template>
          </el-input>
        </template>
        <template slot="commissionPlatformSubLedgerScale">
          <el-input v-model="form.commissionPlatformSubLedgerScale"
                    placeholder="请输入 佣金-平台分账比例"
                    @blur="changeBlur($event,'commissionPlatformSubLedgerScale',100)"
                    onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
            <template slot="append">%</template>
          </el-input>
        </template>

        <template slot="h5Two">
          <h5>房屋租赁</h5>
        </template>
        <template slot="ownerPayServiceFee">
          <el-input v-model="form.ownerPayServiceFee"
                    placeholder="请输入 业主支付服务费"
                    @blur="changeBlur($event,'ownerPayServiceFee',100)"
                    onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
            <template slot="append">%</template>
          </el-input>
        </template>
        <template slot="tenantPayServiceFee">
          <el-input v-model="form.tenantPayServiceFee"
                    placeholder="请输入 租户支付服务费"
                    @blur="changeBlur($event,'tenantPayServiceFee',100)"
                    onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
            <template slot="append">%</template>
          </el-input>
        </template>

        <template slot="servicePaySubLedgerScale">
          <el-input v-model="form.servicePaySubLedgerScale"
                    placeholder="请输入 服务费-支付方分账比例"
                    @blur="changeBlur($event,'servicePaySubLedgerScale',100)"
                    onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
            <template slot="append">%</template>
          </el-input>
        </template>
        <template slot="servicePlatformSubLedgerScale">
          <el-input v-model="form.servicePlatformSubLedgerScale"
                    placeholder="请输入 服务费-平台分账比例"
                    @blur="changeBlur($event,'servicePlatformSubLedgerScale',100)"
                    onkeyup="this.value=this.value.match(/\d+\.?\d{0,2}/)">
            <template slot="append">%</template>
          </el-input>
        </template>
        <template slot="menuForm">
          <el-button icon="el-icon-close" @click="handleClose">取消</el-button>
        </template>
      </avue-form>
    </el-dialog>
  </div>
</template>

<script>
import {add, list, view,update,deleteNew} from '@/api/build/city'
export default {
  name: "index",
  data(){
    return{
      searchData: {
        name: ''
      },
      page:{
        total: 0,
        currentPage: 1
      },
      tableData: [],
      showLoading: false,
      option:{
        title:'城市管理',
        titleSize:'h3',
        card: true,
        border: true,
        header: false,
        stripe: false, // 显示斑马纹
        showHeader: true, // 显示表头
        index: true, // 显示索引
        indexLabel: '序号',
        size: 'small',
        selection: false, // 显示多选框
        page: true, // 显示分页
        align:'center',
        menuAlign:'center',
        menuType: 'text',
        editBtn: false,
        delBtn: false,
        menuWidth: 400,
        column: [
          {
            label: '城市名称',
            prop: 'name'
          }
        ]
      },
      dialogVisible: false,
      form: {
        id: '',
        name: '',
        totalPayServiceFee: '',
        sellerPayServiceFee: '',
        buyerPayServiceFee: '',
        totalPayCommission: '',
        sellerPayCommission: '',
        buyerPayCommission: '',
        enterSubLedgerAmount: '',
        handlerSubLedgerAmount: '',
        rateImage: '',
        ownerPayServiceFee: '',
        tenantPayServiceFee: ''

        // 测试数据
        // name: '测试',
        // totalPayServiceFee: 3.33,
        // sellerPayServiceFee: 2.22,
        // buyerPayServiceFee: 1.11,
        // totalPayCommission: 4.44,
        // sellerPayCommission: 2.22,
        // buyerPayCommission: 2.22,
        // enterSubLedgerAmount: 7.77,
        // handlerSubLedgerAmount: 8.88,
        // rateImage: 'https://ahhfms.oss-cn-beijing.aliyuncs.com/fangchan/2022/08/05/881cab9562534eba8887bc14dc98f858.png',
        // ownerPayServiceFee: 9.99,
        // tenantPayServiceFee: 10.01
      },
      formOption: {
        submitText: '确定',
        emptyBtn: false,
        labelWidth: 180,
        column: [
          {
            label: '',
            prop: 'h3',
            span: 24,
            labelWidth: 0
          },
          {
            label: '城市名称',
            prop: 'name',
            maxlength: 10,
            showWordLimit: true,
            append: '字',
            span: 24,
            rules: [{
              required: true,
              message: "请输入城市名称",
              trigger: "blur"
            }]
          },
          {
            label: '',
            prop: 'h5One',
            span: 24,
            labelWidth: 30
          },
          {
            label: '总支付服务费',
            prop: 'totalPayServiceFee',
            span: 24,
            rules: [{
              required: true,
              message: "请输入总支付服务费",
              trigger: "blur"
            }]
          },
          {
            label: '卖方支付服务费',
            prop: 'sellerPayServiceFee',
            span: 24,
            rules: [{
              required: true,
              message: "请输入卖方支付服务费",
              trigger: "blur"
            }]
          },
          {
            label: '买方支付服务费',
            prop: 'buyerPayServiceFee',
            span: 24,
            rules: [{
              required: true,
              message: "请输入买方支付服务费",
              trigger: "blur"
            }]
          },
          {
            label: '总支付佣金',
            prop: 'totalPayCommission',
            span: 24,
            rules: [{
              required: true,
              message: "请输入总支付佣金",
              trigger: "blur"
            }]
          },
          {
            label: '卖方支付佣金',
            prop: 'sellerPayCommission',
            span: 24,
            rules: [{
              required: true,
              message: "请输入卖方支付佣金",
              trigger: "blur"
            }]
          },
          {
            label: '买方支付佣金',
            prop: 'buyerPayCommission',
            span: 24,
            rules: [{
              required: true,
              message: "请输入买方支付佣金",
              trigger: "blur"
            }]
          },
          {
            label: '服务费-录入人分账金额',
            prop: 'enterSubLedgerAmount',
            span: 24,
            rules: [{
              required: true,
              message: "请输入服务费-录入人分账金额",
              trigger: "blur"
            }]
          },
          {
            label: '服务费-办理人分账金额',
            prop: 'handlerSubLedgerAmount',
            span: 24,
            rules: [{
              required: true,
              message: "请输入服务费-办理人分账金额",
              trigger: "blur"
            }]
          },
          {
            label: '费率图文',
            prop: 'rateImage',
            type: 'upload',
            listType: 'picture-img',
            accept: 'image/jpeg,image/png',
            propsHttp: {
              res: 'data',
              url: 'path',
              name: ''
            },
            headers: {
              Authorization: this.$store.state.user.Token
            },
            action: this.actionUrl,
            tip: '建议尺寸：750*750',
            fileSize: 20 * 1024,
            span: 24
          },
          {
            label: '佣金-支付方分账比例',
            prop: 'commissionPaySubLedgerScale',
            span: 24,
            row: true,
            rules: [{
              required: true,
              message: "请输入佣金-支付方分账比例",
              trigger: "blur"
            }]
          },
          {
            label: '佣金-平台分账比例',
            prop: 'commissionPlatformSubLedgerScale',
            span: 24,
            row: true,
            rules: [{
              required: true,
              message: "请输入佣金-平台分账比例",
              trigger: "blur"
            }]
          },
          {
            label: '',
            prop: 'h5Two',
            span: 24,
            labelWidth: 30
          },
          {
            label: '业主支付服务费',
            prop: 'ownerPayServiceFee',
            span: 24,
            rules: [{
              required: true,
              message: "请输入业主支付服务费",
              trigger: "blur"
            }]
          },
          {
            label: '租户支付服务费',
            prop: 'tenantPayServiceFee',
            span: 24,
            rules: [{
              required: true,
              message: "请输入租户支付服务费",
              trigger: "blur"
            }]
          },
          {
            label: '服务费-支付方分账比例',
            prop: 'servicePaySubLedgerScale',
            span: 24,
            row: true,
            rules: [{
              required: true,
              message: "请输入服务费-支付方分账比例",
              trigger: "blur"
            }]
          },
          {
            label: '服务费-平台分账比例',
            prop: 'servicePlatformSubLedgerScale',
            span: 24,
            row: true,
            rules: [{
              required: true,
              message: "请输入服务费-平台分账比例",
              trigger: "blur"
            }]
          }
        ]
      }
    }
  },
  methods: {
    onLoad(){
      this.showLoading = true;
      list({
        page: this.page.currentPage,
        size: this.page.pageSize,
        ...this.searchData
      }).then(res=>{
        // console.log(res)
        this.showLoading = false;
        if(res.code === 200){
          this.tableData = res.data.records;
          this.page.total = res.data.total;
        }
      })
    },
    // 新增
    handleCreate(){
      // console.log('新增')
      this.dialogVisible = true;
    },
    // 查询
    handleSearch(){
      // console.log('查询')
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 重置
    handleReset(){
      // console.log('重置')
      this.searchData = {
        name: ''
      }
      this.page.currentPage = 1;
      this.onLoad();
    },
    // 编辑
    async handleEdit(row){
      // console.log('编辑')
      const { data } = await view({
        id: row.id
      })
      this.form = {
        id: data.id,
        name: data.name,
        rateImage: data.rateImage,
        buyerPayCommission: data.buyerPayCommission.toFixed(2),
        buyerPayServiceFee: data.buyerPayServiceFee.toFixed(2),
        enterSubLedgerAmount: data.enterSubLedgerAmount.toFixed(2),
        handlerSubLedgerAmount: data.handlerSubLedgerAmount.toFixed(2),
        ownerPayServiceFee: data.ownerPayServiceFee.toFixed(2),
        sellerPayCommission: data.sellerPayCommission.toFixed(2),
        sellerPayServiceFee: data.sellerPayServiceFee.toFixed(2),
        tenantPayServiceFee: data.tenantPayServiceFee.toFixed(2),
        totalPayCommission: data.totalPayCommission.toFixed(2),
        totalPayServiceFee: data.totalPayServiceFee.toFixed(2),

        commissionPaySubLedgerScale: data.commissionPaySubLedgerScale.toFixed(2),
        commissionPlatformSubLedgerScale: data.commissionPlatformSubLedgerScale.toFixed(2),
        servicePaySubLedgerScale: data.servicePaySubLedgerScale.toFixed(2),
        servicePlatformSubLedgerScale: data.servicePlatformSubLedgerScale.toFixed(2),
      };
      console.log(this.form)
      this.dialogVisible = true;
    },
    // 设置区县
    handleRegion(row){
      // console.log('设置区县')
      this.$router.push({
        path: '/build/city/region',
        query: {
          id: row.id
        }
      })
    },
    // 设置地铁
    handleMetro(row){
      // console.log('设置地铁')
      this.$router.push({
        path: '/build/city/metro',
        query: {
          id: row.id
        }
      })
    },
    // 删除
    handleDelete(row){
      // console.log('删除')
      this.$confirm('是否删除此城市数据?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deleteNew({
          id: row.id
        }).then(res => {
          if(res.code === 200){
            this.$message.success('删除成功');
            this.onLoad();
          }else{
            this.$message.error(res.msg);
          }
        })
      })
    },
    // 限制输入框
    changeBlur(e,text,max){
      // console.log(e.target.value,text)
      // console.log(isNaN(e.target.value))
      const value = e.target.value;
      if(value){
        if(isNaN(e.target.value)){
          e.target.value = ''
          this.form[text] = ''
        }else{
          // console.log(Number(value))
          if(Number(value) > Number(max)){
            this.form[text] = Number(max).toFixed(2)
          }else{
            this.form[text] = Number(value).toFixed(2)
          }
        }
      }else{
        e.target.value = '';
        this.form[text] = ''
      }
    },
    // 提交form
    submit(form,done){
      // console.log(form)
      if(Number(form.totalPayServiceFee) !== (Number(form.sellerPayServiceFee) + Number(form.buyerPayServiceFee)).toFixed(2) * 1){
        this.$message.warning('总支付服务费为卖方支付服务费和买方支付服务费的总和')
        return done();
      }
      if(Number(form.totalPayCommission) !== (Number(form.sellerPayCommission) + Number(form.buyerPayCommission)).toFixed(2) * 1){
        this.$message.warning('总支付佣金为卖方支付佣金和买方支付佣金的总和')
        return done();
      }
      if(Number(form.enterSubLedgerAmount) + Number(form.handlerSubLedgerAmount) > Number(form.totalPayServiceFee)){
        this.$message.warning('服务费-录入人分账金额和服务费-办理人分账金额的总和不能大于总支付服务费')
        return done();
      }

      if(Number(form.commissionPaySubLedgerScale) + Number(form.commissionPlatformSubLedgerScale) !== 100){
        this.$message.warning('佣金-支付方分账比例和佣金-平台分账比例总和为100');
        done();
        return;
      }
      if(Number(form.servicePaySubLedgerScale) + Number(form.servicePlatformSubLedgerScale) !== 100){
        this.$message.warning('服务费-支付方分账比例和服务费-平台分账比例总和为100');
        done();
        return;
      }
      // console.log(Number(form.ownerPayServiceFee))
      // console.log(Number(form.tenantPayServiceFee))
      // console.log(Number(form.ownerPayServiceFee) + Number(form.tenantPayServiceFee))
      if((Number(form.ownerPayServiceFee) + Number(form.tenantPayServiceFee)).toFixed(2) * 1 !== 100){
        this.$message.warning('房屋租赁-业主支付服务费和租户支付服务费的总和为100%')
        return done();
      }
      if(form.id){
        update(form).then(res => {
          if(res.code === 200){
            this.$message.success('修改成功');
            this.dialogVisible = false;
            setTimeout(()=>{
              this.resetForm();
            },500)
            this.onLoad();
          }else{
            this.$message.error(res.msg);
            done();
          }
        }).catch(()=> done())
      }else{
        add(form).then(res => {
          if(res.code === 200){
            this.$message.success('新增成功');
            this.dialogVisible = false;
            setTimeout(()=>{
              this.resetForm();
            },500)
            this.onLoad();
          }else{
            this.$message.error(res.msg);
            done();
          }
        }).catch(()=> done())
      }
    },
    // 关闭弹框
    handleClose(){
      this.dialogVisible = false;
      setTimeout(()=>{
        this.resetForm();
      },500)
    },
    resetForm(){
      // 重置form
      this.form = {
        id: '',
        name: '',
        totalPayServiceFee: '',
        sellerPayServiceFee: '',
        buyerPayServiceFee: '',
        totalPayCommission: '',
        sellerPayCommission: '',
        buyerPayCommission: '',
        enterSubLedgerAmount: '',
        handlerSubLedgerAmount: '',
        rateImage: '',
        ownerPayServiceFee: '',
        tenantPayServiceFee: ''
      }
    },
    uploadDelete(file,column) {
      return this.$confirm(`是否确定移除？`);
    },
    uploadError(error, column) {
      this.$message.error(error);
    },
    uploadBefore(file, done, loading,column) {
      if (file.type === '' || (column.accept.indexOf(file.type) < 0)){
        this.$message.error('文件格式有误');
        loading()
      }else {
        done();
      }
    },
    uploadAfter(res, done,loading,column) {
      done()
      // console.log(res)
      // console.log(column)
      if(!res.path){
        this.$message.error('上传失败');
        loading()
      }else{
        column.propsHttp.name = res.path;
      }
    },
  }
}
</script>

<style scoped>

</style>